<template>
  <div class="product">
    <div class="flex align-center justify-between mg-b-15">
      <div class="flex align-center">
        <h2 class="mg-r-15">{{$t('productlist.management')}}</h2>
        <router-link :to="`products/new-${me.store.id}/edit`" class="btn btn--green flex-20 mg-l-20"><i class="fas fa-plus-circle"/> {{$t('productlist.add')}}</router-link>
        <button class="btn btn--red mg-l-10" @click="cloneProduct">{{$t('productlist.clone')}}</button>
      </div>
      <div class="flex align-center justify-end">
        <button class="btn btn--green mg-r-10" @click="onToggle(true)">{{$t('productlist.publish')}}</button>
        <button class="btn btn--green-outline" @click="onToggle(false)">{{$t('productlist.unpublish')}}</button>
      </div>
    </div>

    <form @submit.prevent="searchItems" class="product__search flex align-center justify-start mg-b-15 mg-t-15 pd-15">
      <div class="form-element mg-r-15">
        <input type="text" v-model="query" :placeholder="$t('global.search')" class="form-control"/>
      </div>
      <label for="status">{{$t('productlist.search_status')}}</label>
      <div class="form-element mg-r-15 mg-l-15">
        <select v-model="query_status" id="status" class="form-control">
          <option v-for="(item, index) in productStatus" :key="index" :value="index">{{ $t(productStatus[index]) }}</option>
        </select>
      </div>
      <label for="type">{{$t('productlist.search_type')}}</label>
      <div class="form-element mg-r-15 mg-l-15">
        <select v-model="query_product_type" id="type" class="form-control">
          <option v-for="(item, index) in productType" :key="index" :value="index">{{ $t(productType[index]) }}</option>
        </select>
      </div>
      <button type="submit" class="btn btn--red">{{$t('global.search_btn')}}</button>
      <button type="button" class="btn btn--green mg-l-15" @click.prevent="resetSearch">{{$t('global.reset_btn')}}</button>
    </form>
    <div class="table">
      <div class="table__head">
        <div class="w-50 flex align-center justify-start">
          <Checkbox :checked="checkAll" @change="toggleCheckbox"/>
          {{$t('global.no')}}
        </div>
        <div class="w-125">{{$t('productlist.status')}}</div>
        <div class="w-125">{{$t('productlist.type')}}</div>
        <div class="w-200">{{$t('productlist.product')}}</div>
        <div class="w-100">{{$t('productlist.original_price')}}</div>
        <div class="w-100">{{$t('productlist.price')}}</div>
        <div class="w-200">{{$t('productlist.promo_type')}}</div>
        <div class="w-150">{{$t('productlist.promo_price')}}</div>
        <div class="w-100">{{$t('productlist.points')}}</div>
        <div class="w-350">{{$t('productlist.merchant_notes')}}</div>
        <div class="w-100">{{$t('global.actions')}}</div>
      </div>
      <div v-for="(item, index) in list" :key="index" class="table__line">
        <div class="w-50 flex align-center justify-start">
          <Checkbox :checked="item.selected" @change="list[index].selected = !list[index].selected"/>
          {{ index + 1 }}
        </div>
        <div class="w-125 flex align-center justify-start">
          <div class="dot mg-r-10" :class="{
            warning: item.status !== 3 && item.status !== 2,
            success: item.status === 2 || item.status === 3
          }"/>
          <div>{{$t(productStatus[item.status])}}</div>
        </div>
        <div class="w-125">
          <div>
            {{$t(productType[item.type])}}
          </div>
        </div>
        <div class="w-200">
          <div>
            {{item.name}}
          </div>
          <div class="red">
            {{item.name_c}}
          </div>
        </div>
        <div class="w-100">{{$filters.formatPrice(item.original_price)}}</div>
        <div class="w-100">{{$filters.formatPrice(item.sell_price)}}</div>
        <div class="w-200">
          <div>
            {{item.promotion_type ? $t(promoTypes[item.promotion_type]) : '-'}}
          </div>
        </div>
        <div class="w-150 green">{{item.promotion_price ? $filters.formatPrice(item.promotion_price) : '-'}}</div>
        <div class="w-100">{{item.points || '-'}}</div>
        <div class="w-350 flex align-center justify-start">
          <div class="flex-80 form-element">
            <input type="text" v-model="item.merchant_comments" class="form-control"/>
          </div>
          <button class="flex-20 mg-l-10 mg-r-20 btn btn--red round" @click="saveComments(item.store_id, item.id, item.merchant_comments)">{{$t('global.save')}}</button>
        </div>
        <div class="actions w-100">
          <router-link :to="`products/${item.id}-${item.store_id}`" class="btn btn--orange" target="_blank"><i class="fas fa-eye"/></router-link>
          <router-link :to="`products/${item.id}-${item.store_id}/edit`" class="btn btn--green" target="_blank"><i class="fas fa-pen"/></router-link >
          <button class="btn btn--red" @click="onDelete(index)"><i class="fas fa-trash-alt"/></button>
        </div>
      </div>
    </div>
    <div class="flex justify-center mg-t-20" v-if="hasMore">
      <button class="btn btn--red" @click.prevent="loadProducts">{{$t('productlist.load_more')}}</button>
    </div>
    <Loading v-if="isLoading"/>
  </div>
</template>

<script>

import Checkbox from '@/components/checkbox/checkbox'
import Loading from '@/components/loading/Loading'
import { Product } from '@/api/api'
import { productStatus, promoTypes, productType } from '@/config/config'
import { mapGetters } from 'vuex'

export default {
  name: 'ProductList',
  components: {
    Loading,
    Checkbox
  },
  data () {
    return {
      isLoading: true,
      page: 1,
      query: '',
      query_status: '',
      query_product_type: '',
      hasMore: true,
      checkAll: false,
      list: []
    }
  },
  computed: {
    ...mapGetters({
      isAuthed: 'auth/isAuthed',
      me: 'auth/me'
    }),
    productStatus () {
      return productStatus
    },
    promoTypes () {
      return promoTypes
    },
    productType () {
      return productType
    }
  },
  mounted () {
    this.loadProducts()
  },
  methods: {
    toggleCheckbox () {
      this.list = this.list.map((i) => {
        i.selected = !this.checkAll

        return i
      })

      this.checkAll = !this.checkAll
    },
    searchItems () {
      this.page = 1
      this.list = []

      this.loadProducts()
    },
    resetSearch () {
      this.query = null
      this.query_status = null
      this.query_product_type = null
      this.page = 1
      this.list = []
      this.loadProducts()
    },
    async loadProducts () {
      this.isLoading = true

      try {
        const resp = await Product.merchantAll(this.me.store.id, {
          page: this.page,
          search_term: this.query,
          status_id: this.query_status,
          product_type: this.query_product_type
        })

        const list = [
          ...this.list,
          ...resp.data.data
        ]

        if (list.length >= resp.data.total) {
          this.hasMore = false
        }

        this.page = this.page + 1
        this.list = [...list]
      } catch (err) {
        this.$toast.error(this.$t('productlist.load_fail'))
      } finally {
        this.isLoading = false
      }
    },
    async onDelete (index) {
      this.isLoading = true

      try {
        await Product.merchantDelete(this.list[index].store_id, this.list[index].id)

        const products = [...this.list]

        products.splice(index, 1)

        this.list = products
      } catch (e) {
        this.$toast.error(this.$t('productlist.delete_fail'))
      } finally {
        this.isLoading = false
      }
    },
    async onToggle (publish) {
      this.isLoading = true

      try {
        const ids = this.list.map((i) => {
          return i.selected ? i.id : null
        }).filter((i) => !!i)

        await Product.toggleProducts(this.list[0].store_id, publish, ids)

        this.list = this.list.map((i) => {
          if (ids.includes(i.id)) {
            i.status = publish && i.status === 2 ? 3 : (!publish && i.status === 3 ? 2 : i.status)
            i.selected = false
          }

          return i
        })
      } catch (e) {
        this.$toast.error(this.$t('productlist.delete_fail'))
      } finally {
        this.isLoading = false
      }
    },

    async cloneProduct () {
      this.isLoading = true

      try {
        const ids = this.list.map((i) => {
          return i.selected ? i.id : null
        }).filter((i) => !!i)

        await Product.clone(this.list[0].store_id, ids)
      } catch (e) {
        this.$toast.error(this.$t('productlist.clone_fail'))
      } finally {
        this.isLoading = false
      }

      location.reload()
    },

    async saveComments (store, product, comments) {
      this.isLoading = true

      try {
        await Product.merchantComment(store, product, comments)

        this.$toast.success(this.$t('productlist.comments_saved'))
      } catch (err) {
        this.$toast.error(this.$t('productlist.comments_failed'))
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "style";
</style>
